/* QR Main Page */

.custom-qr-wrapper {
    margin-top: 2rem;
    margin-bottom:0 ;
}

.custom-qr-wrapper .text-center{
    /* background-color: sandybrown; */
    margin-bottom: 40px;
}

.custom-qr-wrapper .text-center .close-icon {
    color: black;
    text-decoration: none ;
    font-size:32px ;
    display:flex ;
    flex-direction:end ;
    margin-top: -40px;
    /* margin-right: -40px; */
    /* padding-right:-40px ;  */
    /* background-color: seagreen; */
}


/* QR Main Comp */

.qr-comp-wrapper {
    width:200px; 
    height:211.2px;
    border:2px solid lightgray;
    /* margin-bottom:2px; */
    /* margin-right:2px; */
    /* padding-top: 2px;
    background-color: lightgreen; */
}

.qr-comp-wrapper .qr-comp-container{
    font-size:small;
    justify-content:center;
    align-items:center;
    display:flex;
    padding-top: 1px;
    /* padding:4px; */
    /* background-color: sandybrown; */
    font-weight: 600;
}


/* ---------------------- */

/* QR Final Component */

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    /* background-color: lightgreen; */
    /* margin-bottom:20px ; */
}
  
.row .column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    width: 90%;
    align-items:flex-start ;
}

.row .column2 {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    width: 90%;
    align-items:flex-end ;
    /* background-color:sandybrown; */
}


.row .column3 {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    width: 90%;
    align-items:flex-start ;
    /* background-color: seagreen; */
}

.row .column4 {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 3;
    width: 90%;
    align-items:flex-start ;
    /* background-color: sandybrown; */
}

.row .column .head-wrapper{
    /* margin-top: 40px; */
    margin-bottom: 20px;
    /* background-color: sienna; */
}

.row .column .head-wrapper>h4{
    font-size:x-large ;
    margin-bottom: 20px;
    /* background-color: seagreen; */
}

.row .column .head-wrapper>input{
    height: 40px;
    padding-left: 10px;
    width: 140px;
    /* background-color: sandybrown; */
}

.row .column .qr-btn{
    width: 180px;
    margin-bottom: 40px;
    
}


.row .column2 .last-kit{
    /* margin-right:80px ; */
    width: 240px;
    margin-top: 20px;
    margin-bottom: 40px;
    /* background-color: seagreen; */
    border: 1px solid gray;
    border-radius: 4px;
    justify-content: center;
    justify-items: center;
    padding: 8px;
}

.row .column2 .last-kit>h5{
    width: 198px;
    font-size: larger;
    font-weight: 300 ;
    /* background-color: greenyellow; */
    justify-items:flex-end ;
    border-radius: 4px;
}

.row .column2 .last-kit .last-kit-val{
    margin-left: 4px;
    /* border-radius:inherit ; */
    background-color:lightgoldenrodyellow ;
    width: 104px;
    border-radius: 4px;
}

.row .column2 .last-kit>button{
    border-radius: 0px;
}


.qr-display{
    display:flex;
    flex-direction:row ; 
    padding-right: 4px;
    padding-left: 4px;
    /* padding:4px;  */
    flex-wrap:wrap;
    justify-content:flex-start ;
    border:1px solid gray;
    min-height:460px;
}

.row .column4 .qr-popup{
    /* background-color: yellow; */
    width: 300px;
    height: 80px;
    padding-left: 20px;
    padding-top: 6px;
    border: 1px solid gray;
    border-radius: 4px;
    margin-top: -20px;
    margin-left: -200px;
    margin-bottom: 10px;
}

.row .column4 .qr-popup .qr-popup-btn{
    /* background-color: turquoise; */
    margin-right: 10px;
}

.qr-popup>h1{
    font-size: large;
}

.print-btn{
    /* margin-bottom: 20px; */
    /* background-color: lightgreen; */
    height: 40px;
    width: 100px;
    font-size: large;
    font-weight: 500;
    justify-content: center;
    margin-bottom: 10px;
    margin-top: -20px;
}

/* ---------------------- */

/* GenQR Component */

.gen-qr-main{
    justify-content:center ;
    align-items:center ;
    display: flex;
    padding-right: 4px;
    padding-left: 4px;
    padding-bottom: 4px;
    /* background-color: lightgreen; */
}











.custom-update {
    margin-top: 2rem;
    margin-bottom:0 ;
}

.custom-update .text-center{
    
}

.close-icon {
    color: black;
    text-decoration: none ;
    font-size:32px ;
    display:flex ;
    flex-direction:row-reverse ;
    padding-right:150px ;
}
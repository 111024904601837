.form-container {
    margin: 4rem;
    margin-top: 12rem;
}

.form-container>h1 {
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 1.5rem;
}

.btn-container>p {
    align-items: center;
    justify-content: center;
    text-align: center;
}
.custom-update-container {
    align-items: center;
    justify-content: center;
    justify-self: center;
    margin-top:2rem;
    margin-bottom:2rem;
    margin-left: 10rem;
    margin-right: 10rem;
}

.print-wrapper{
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 10px;
}

.print-icon{
    display: flex;
    justify-content: center;
    width: 40px;
    height: 40px;
}

.custom-update-btn {
    display: flex;
    margin: auto;
}

.custom-update {
    margin-top: 2rem;
    margin-bottom:0 ;
}

.custom-update .text-center{
    
}

.close-icon {
    color: black;
    text-decoration: none ;
    font-size:32px ;
    display:flex ;
    flex-direction:row-reverse ;
    padding-right:150px ;
}

.required-field{
    width:200px;
    /* background-color: sienna; */
}

.required-field::after {
    content: "*";
    color: red;
    font-size: large;
}

.custom-form-errors p{
    color: red;
}

.print-btn{
    color:black ;
    display: flex;
    flex-direction: row-reverse;
    margin-right: 160px;
    font-size: 40px;
}




.custom-update-container .row{
    /* background-color: sienna; */
}



.custom-update-container .row .col-dropdowns{
    width: 240px;
    /* background-color: seagreen; */
}

.custom-update-container .row .col-dropdowns .custom-drops{
    margin-left: -4px;
    width: 220px;
    /* background-color: sandybrown; */
}

.custom-update-container .row .col .label-custom{
    margin-right: 20px;
    margin-left: 10px;
    margin-top: 8px;
    margin-bottom: 8px;
}

.custom-update-container .row .col .label-custom input{
    margin-right: 4px;
}
.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    /* background-color: burlywood; */
}
  
.row .column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    width: 90%;
    /* justify-content: center; */
    align-items:flex-start ;
    /* background-color: cadetblue; */
}
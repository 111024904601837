.custom-home {
    margin: 1rem;
    min-height:90vh ;
}

.custom-home .footer p{
    width: fit-content;
    font-size: x-small;
    /*position:relative ;
    bottom: 0;*/
    position: absolute;
    bottom: 0;
}

.footer p{
    width: fit-content;
    font-size: x-small;
    /*position:relative ;
    bottom: 0;*/
    position: relative;
    bottom: 0;
}

.footer {
    display: flex;
    justify-content: center;
    align-items:center ;
    text-align: center;
}

.custom-header {
    margin: 1rem;
    display:flex ;
    justify-content: center;
}

.custom-date-input {
    margin: 2rem;
}

.custom-btn {
    margin: 2rem;
}











/* Header */

.display-6{
    font-size:xx-large ;
    margin-bottom: -10px;
}







/* Nav Bar */
.navbar-btn{
    /* background-color: lightgreen; */
    margin-left: 10px;
    margin-right: 40px;
}

.navbar-btn .btn-features{
    margin-left: 10px;
}
.custom-view-btn {
    color: red;
}

.custom-form {
    margin-left: 20px;
}

.print-icon{
    font-size: x-large;
}

.print-icon:hover{
    cursor: pointer;
}

.dropdown-wrapper{
    background-color: white;
    display: flex;
    justify-content: center;
    height: 80px;
    vertical-align: middle;
}

.input-group-text2{
    height:50px ;
    width: 600px;
    font-size:x-large ;
    font-weight: 400;
    box-shadow: 0 4px 8px 2px rgba(0,0,0,0.2);
    border: none;
    padding-left: 20px;
}


.custom-form .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
}
  


.custom-form .row .search-wrapper{
    height: fit-content;
    background-color:#feddb6;
    vertical-align: middle;
    display: flex;
    padding: 15px;
    margin:10px;
}

.custom-form .row .search-wrapper2{
    height: fit-content;
    background-color:#cce6e6 ;
    padding: 15px;
    margin:10px;
}

.custom-form .row .search-wrapper3{
    height: fit-content;
    background-color:#CBC3E3 ;
    padding: 15px;
    margin:10px;
}


.custom-form .row .search-wrapper h4{
    font-weight:300;
    font-size:large ;
}

.custom-form .row .search-wrapper2 .col {
    display: flex;
    flex-direction: row;
    flex-basis: 100%;
    flex: 1;
    justify-content: center;
    align-items: center;
}

.custom-form .row .search-wrapper3 .col {
    display: flex;
    flex-direction: row;
    flex-basis: 100%;
    flex: 1;
    justify-content: center;
    align-items: center;
}




.custom-form .row .search-wrapper2 .row .col-3{
    width: 240px;
    vertical-align: middle;
}

.custom-form .row .search-wrapper2 .row .col-3 h4{
    font-weight:300;
    font-size:large ;
    padding-top: 8px;
}

.custom-form .row .search-wrapper3 .row .col-3{
    width: 240px;
    vertical-align: middle;
}

.custom-form .row .search-wrapper3 .row .col-3 h4{
    font-weight:300;
    font-size:large ;
    padding-top: 8px;
    width: 250px;
    margin-left: -40px;
}





.custom-form .row .search-wrapper .row .col-3{
    width: 240px;
    vertical-align: middle;
}

.custom-form .row .search-wrapper .row .col-3 h4{
    font-weight:300;
    font-size:large ;
    padding-top: 10px;
}

.custom-form .row .search-wrapper .row .col-4{
    width: 600px;
    vertical-align: middle;
}
.custom-update {
    margin: 1.2rem;
}

.custom-update .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
}
  
.custom-update .row .column {
    display: flex;
    flex-direction: row-reverse;
    flex-basis: 100%;
    flex: 1;
    width: 90%; 
    float: right;
}

.custom-update .row .column .text-center{
    font-size:x-large ;
    width: fit-content;
    height: fit-content;
    margin-right: -120px;
}

.custom-update .row .column .close-icon {
    color: black;
    text-decoration: none ;
    font-size:x-large ;
    display: flex;
    justify-content:flex-end ;
}


@media print
{    
    .no-print, .no-print *
    {
        display: none !important;
    }

    .text-center{
        font-size: xx-small;
    }

    html, body { height: auto; } /* Fixed the issue of printing in two pages*/

}

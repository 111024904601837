html, body { height: auto; }

.wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    justify-items: center;
    width: fit-content;
    height: fit-content;
    
}

.wrapper2{
    display: flex;
    justify-content: center;
    align-items: center;
    justify-items: center;
    width: fit-content;
    height: fit-content;
}


.wrapper .table2{
    display: flex;
    justify-content: center;
    width: fit-content;
    height: fit-content;
    margin-top: 10px;
}

.wrapper2 .table3{
    display: flex;
    justify-content: center;
    width: fit-content;
    height: fit-content;
    margin-top: 20px;
}
/*   background-color: seagreen;  */

.wrapper .table2 .row2{
    height: 30px;
    width: 800px;
}


.wrapper2 .table3 .additional-info{
    height: 30px;
    width: 800px;
}


.wrapper2 .table3 .test-info{
    font-size:large ;
    font-weight: 600;
    margin-top: 20px;
}

.wrapper2 .table3 .empty-space{
    height: 140px;
}


.wrapper2 .table3 .footer{
    font-size: smaller;
    position: relative;
    bottom: 0;
    text-align: center; 
}

.wrapper .table2 .row2 .label{
    text-align: right;
    font-size:medium ;
    font-weight: 500;
    width: 130px;
}

.wrapper .table2 .row2 .value{
    font-size:medium ;
    font-weight: 600;
    padding-left: 20px;
    width: 200px;
}

.wrapper .table2 .row2 .value-result{
    font-size:medium ;
    font-weight: 600;
    padding-left: 20px;
    width: 200px;
}

.wrapper .table2 .row2 .value-result2{
    font-size:medium ;
    font-weight: 600;
    width: 200px;
}

.wrapper .table2 .row2 .value-name{
    font-size:medium ;
    font-weight: 600;
    padding-left: 20px;
    width: 200px;

}


.wrapper2 .table3 .additional-info .label3{
    text-align: right;
    font-size:medium ;
    font-weight: 500;
    width: 180px;
    padding-right: 4px;
    border: 1px solid black;
}

.wrapper2 .table3 .additional-info .label4{
    text-align: right;
    font-size:medium ;
    font-weight: 500;
    width: 180px;
    padding-right: 4px;
    border: 1px solid black;
}


.wrapper2 .table3 .additional-info .value2{
    font-size:medium ;
    font-weight: 600;
    padding-left: 20px;
    width: 600px;
    border: 1px solid black;
}


.wrapper2 .table3 .additional-info .value4{
    font-size:medium ;
    font-weight: 600;
    padding-left: 20px;
    width: 400px;
    border: 1px solid black;
}


.wrapper2 .table3 .additional-info .value2 .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
}
  
.wrapper2 .table3 .additional-info .value2 .row .column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    width: 90%;
   
}





.wrapper2 .table3 .additional-info .value4 .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
}
  
.wrapper2 .table3 .additional-info .value4 .row .column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    width: 90%;
   
}



@media print {
    html, body { height: auto; } /* Fixed the issue of printing in two pages*/
}